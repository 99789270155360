import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import { Link } from "gatsby"
import { Box, Container, Themed } from "theme-ui"

const NotFoundPage = () => {

  return (
    <Box
      as={`div`}
      sx={{
        header: {
          backgroundColor: `black`,
        },
        main: {
          backgroundColor: `black`,
          py: 6,
        },
        footer: {
          m: 0,
        },
        h1: {
          color: `white`,
        },
        a: {
          color: `white`,
          "&:hover": {
            color: `white`,
          }
        }
      }}
    >
      <Header/>
      <Box as={`main`}>
        <Container>
          <Themed.h1>Not found</Themed.h1>
          <Themed.p>Sorry, the requested URL was not found on the server.</Themed.p>
          <Themed.p><Themed.a as={Link} to="/" aria-label="home">&#x02190; Home</Themed.a></Themed.p>
        </Container>
      </Box>
      <Footer/>
    </Box>
  )

}

export default NotFoundPage